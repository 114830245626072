export default {
  namespaced: true,
  state: {
    keepAliveArr: [],
  },
  mutations: {
    keepAlive(state, component) {
      component &&
        !state.keepAliveArr.includes(component) &&
        state.keepAliveArr.push(component);
    },
    noKeepAlive(state, component) {
      const index = state.keepAliveArr.indexOf(component);

      index !== -1 && state.keepAliveArr.splice(index, 1);
    },
  },
  getters: {
    getKeepAlive(state) {
      return state.keepAliveArr || [];
    },
  },
};
