export const user_router = {
  path: "/user/index",
  name: "user",
  meta: {
    requiresAuth: true,
    breadcrumbName: "代理管理",
    menuPath: "/user/index",
  },
  menuName: "代理管理",
  icon: "el-icon-connection",
  component: () => import("@/views/user/index.vue"),
};
