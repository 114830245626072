export const finance_recharge_router = {
  path: "/finance/recharge",
  name: "recharge",
  meta: {
    requiresAuth: true,
    breadcrumbName: "充值管理",
    menuPath: "/finance/recharge",
  },
  menuName: "充值管理",
  icon: "el-icon-data-line",
  component: () => import("@/views/finance/recharge/index.vue"),
};
