export const notice_router = {
  path: "/notice/index",
  name: "notice",
  meta: {
    requiresAuth: true,
    breadcrumbName: "公告管理",
    menuPath: "/notice/index",
  },
  icon: "el-icon-bell",
  component: () => import("@/views/notice/index.vue"),
};
