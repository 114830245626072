export const settlement_router = {
  path: "/settlement/index",
  name: "settlement",
  meta: {
    requiresAuth: true,
    breadcrumbName: "结算管理",
    menuPath: "/settlement/index",
  },
  menuName: "结算管理",
  icon: "el-icon-finished",
  component: () => import("@/views/settlement/index.vue"),
};
