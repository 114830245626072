export const shop_router = {
  path: "/shop",
  redirect: "/shop/list",
  meta: { requiresAuth: true, breadcrumbName: "店铺管理" },
  menuName: "店铺管理",
  icon: "el-icon-s-shop",
  component: () => import("@/components/KeepAliveInclude.vue"),
  children: [
    {
      path: "/shop/list",
      name: "shop_list",
      meta: {
        requiresAuth: true,
        breadcrumbName: "店铺列表",
        menuPath: "/shop/list",
        keepAlive: true,
      },
      menuName: "店铺列表",
      icon: "el-icon-s-shop",
      component: () => import("@/views/shop/list.vue"),
    },
    {
      path: "/shop/order",
      name: "shop_order",
      meta: {
        requiresAuth: true,
        breadcrumbName: "店铺订单",
        menuPath: "/shop/order",
        keepAlive: true,
      },
      menuName: "店铺订单",
      icon: "el-icon-s-promotion",
      component: () => import("@/views/shop/order.vue"),
    },
  ],
};
