import api from "./api";
// 代理配置
const TMPURL =
  process.env.NODE_ENV === "development" ? "/api" : process.env.VUE_APP_API;

// 验证码
export function captcha(params) {
  return api.get(TMPURL + "/api/user/staff/captcha", params);
}

// 登录
export function login(params) {
  return api.post(TMPURL + "/api/user/staff/login", params);
}

// 修改登录密码
export function changePassword(params) {
  return api.post(TMPURL + "/api/user/staff/changepassword", params);
}

// 修改二级密码
export function changePassword2(params) {
  return api.post(TMPURL + "/api/user/staff/changepassword2", params);
}

// 个人信息
export function userInfo(params) {
  return api.get(TMPURL + "/api/user/staff/mine", params);
}

// 公共数据
export function commonData(params) {
  return api.get(TMPURL + "/api/user/common/commondata", params);
}

// 上传
export function upload() {
  return TMPURL + "/api/user/file/upload";
}

// 生成google验证码
export function getGoogleAuth(params) {
  return api.get(TMPURL + "/api/user/staff/getgoogleAuth", params);
}

// 绑定google验证码
export function bandGoogleAuth(params) {
  return api.get(TMPURL + "/api/user/staff/bandgoogleAuth", params);
}
