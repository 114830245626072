import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/common.less";

import router from "./router";
import store from "./store";

import { timeFormat } from "@/util/util";

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$eventBus = new Vue();

// 过滤器
Vue.filter("timeFormat", function (value, format = "YYYY-MM-DD HH:mm:ss") {
  if (!value) {
    return "";
  }
  return timeFormat(value * 1000, format);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
