export const order_router = {
  path: "/order/index",
  name: "order",
  meta: {
    requiresAuth: true,
    breadcrumbName: "订单管理",
    menuPath: "/order/index",
  },
  menuName: "订单管理",
  icon: "el-icon-s-order",
  component: () => import("@/views/order/index.vue"),
};
