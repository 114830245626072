import Vue from "vue";
import Vuex from "vuex";
import common from "./modules/common";

// keepalive 数组
import keepAlive from "./modules/keepAlive";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    common: common,
    keepAlive: keepAlive,
  },
});
