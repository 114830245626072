export const finance_cash_router = {
  path: "/finance/cash",
  name: "cash",
  meta: {
    requiresAuth: true,
    breadcrumbName: "提现管理",
    menuPath: "/finance/cash",
  },
  menuName: "提现管理",
  icon: "el-icon-bank-card",
  component: () => import("@/views/finance/cash/index.vue"),
};
