import storage from "@/util/storage";
import { userInfo, commonData } from "@/api/common_api";

const state = {
  adminer: {},
  userInfo: {},
  userLoading: false,
  route: [],
  commonData: {},
  commonDataLoading: false,
};

const mutations = {
  // 管理员登录
  ADMINLOGIN(state, adminer) {
    state.adminer = JSON.parse(JSON.stringify(adminer));
    storage.setItem("adminer", adminer);
  },
  // 管理员退出
  ADMINLOGOUT(state) {
    state.adminer = {};
    storage.removeItem("adminer");
    state.route = [];
    storage.removeItem("route");
    state.userInfo = {};
    storage.removeItem("userInfo");
    state.commonData = {};
    storage.removeItem("commonData");
  },
  // 存储路由
  SETROUTE(state, route) {
    storage.setItem("route", route);
    state.route = JSON.parse(JSON.stringify(route));
  },
  SETUSERINFO(state, userInfo) {
    state.userInfo = JSON.parse(JSON.stringify(userInfo));
    storage.setItem("userInfo", userInfo);
  },
  SETCOMMONDATA(state, commonData) {
    state.commonData = JSON.parse(JSON.stringify(commonData));
    storage.setItem("commonData", commonData);
  },
};

// getters
const getters = {
  adminer: function (state) {
    let adminer = {};
    if (state.adminer && state.adminer.id) {
      adminer = state.adminer;
    }
    if (storage.getItem("adminer")) {
      adminer = storage.getItem("adminer");
    }
    return adminer;
  },
  userInfo: function (state) {
    let userInfo = {};
    if (state.userInfo && state.userInfo.id) {
      userInfo = state.userInfo;
    }
    if (storage.getItem("userInfo")) {
      userInfo = storage.getItem("userInfo");
    }
    return userInfo;
  },
  userLoading: () => {
    return state.userLoading;
  },
  commonData: function (state) {
    let commonData = {};
    if (state.commonData && Object.keys(state.commonData).length) {
      commonData = state.commonData;
    }
    if (storage.getItem("commonData")) {
      commonData = storage.getItem("commonData");
    }
    return commonData;
  },
  commonDataLoading: () => {
    return state.commonDataLoading;
  },
  route: (state) => {
    let route = [];
    if (state.route && state.route.length) {
      route = state.route;
    }
    if (storage.getItem("route")) {
      route = storage.getItem("route");
    }
    return route;
  },
};

// 创建驱动actions可以使得mutations得以启动
const actions = {
  // 管理员登录
  adminlogin({ commit }, data) {
    commit("ADMINLOGIN", data);
  },
  // 管理员退出
  adminlogout({ commit }) {
    commit("ADMINLOGOUT");
  },
  // 设置路由
  setroute({ commit }, data) {
    commit("SETROUTE", data);
  },
  getuserinfo({ commit }) {
    state.userLoading = true;
    userInfo()
      .then((res) => {
        if (res.code == 200) {
          commit("SETUSERINFO", res.data);
          state.userLoading = false;
        }
      })
      .catch((err) => {
        console.log(err);
        state.userLoading = false;
      });
  },
  getcommonData({ commit }) {
    state.commonDataLoading = true;
    commonData()
      .then((res) => {
        if (res.code == 200) {
          state.commonDataLoading = false;
          commit("SETCOMMONDATA", res.data);
        }
      })
      .catch((err) => {
        state.commonDataLoading = false;
        console.log(err);
      });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
