<template>
  <div id="app">
    <router-view />
  </div>
</template>
<style lang="less" scoped></style>
<script>
import {
  getTheme,
  getThemeChalk,
  getThemeCluster,
  updateStyle,
} from "@/util/color";
const ORIGINAL_THEME = "#409EFF";
import ResizeMixins from "@/util/ResizeMixins";
export default {
  mixins: [ResizeMixins],
  name: "App",
  mounted() {
    this.$eventBus.$on("resize", () => {
      this.setViewport();
    });
  },
  beforeMount() {
    this.setViewport();
    let default_theme = getTheme();
    if (default_theme) {
      let default_theme_chalk = getThemeChalk();
      const themeCluster = getThemeCluster(default_theme.replace("#", ""));
      const originalCluster = getThemeCluster(ORIGINAL_THEME.replace("#", ""));
      const newStyle = updateStyle(
        default_theme_chalk,
        originalCluster,
        themeCluster
      );
      let styleTag = document.getElementById("chalk-style");
      if (!styleTag) {
        let styleTag = document.createElement("style");
        styleTag.setAttribute("id", "chalk-style");
        document.head.appendChild(styleTag);
      }
      document.getElementById("chalk-style").innerText = newStyle;

      document
        .getElementsByTagName("body")[0]
        .style.setProperty("--common-color", default_theme);
      document
        .getElementsByTagName("body")[0]
        .style.setProperty(
          "--defaultbutton-border-hover",
          themeCluster[themeCluster.length - 4]
        );
      document
        .getElementsByTagName("body")[0]
        .style.setProperty(
          "--defaultbutton-bac-hover",
          themeCluster[themeCluster.length - 2]
        );
    }
  },
  methods: {
    setViewport() {
      const screenWidth = window.screen.width;
      let dpr = window.devicePixelRatio;
      let rate = screenWidth / 1920;
      let scale = rate * dpr;
      if (scale > 1) {
        scale = 1;
      }
      let viewport = document.querySelector('meta[name="viewport"]');
      if (!viewport) {
        viewport = document.createElement("meta");
        viewport.setAttribute("name", "viewport");
        window.document.head.appendChild(viewport);
      }
      viewport.setAttribute(
        "content",
        "width=device-width,initial-scale=" + scale
      );
    },
  },
};
</script>
