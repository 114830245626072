export const refund_router = {
  path: "/refund/index",
  name: "refund",
  meta: {
    requiresAuth: true,
    breadcrumbName: "退款管理",
    menuPath: "/refund/index",
  },
  menuName: "退款管理",
  icon: "el-icon-coin	",
  component: () => import("@/views/refund/index.vue"),
};
