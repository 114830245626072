export const dashboard_router = {
  path: "/dashboard",
  redirect: "/dashboard/index",
};

export const dashboard_index_router = {
  path: "/dashboard/index",
  name: "dashboard",
  meta: {
    requiresAuth: true,
    breadcrumbName: "首页",
    menuPath: "/dashboard/index",
  },
  menuName: "首页",
  icon: "el-icon-s-home",
  component: () => import("@/views/dashboard/index.vue"),
};
