import _ from "lodash";
export default {
  mounted() {
    let _clientWidth = document.body.clientWidth;

    window.onresize = _.debounce(() => {
      let _currentClientWidth = document.body.clientWidth;

      if (_currentClientWidth != _clientWidth) {
        this.resize();
        _clientWidth = _currentClientWidth;
      }
    }, 1000);
  },
  methods: {
    resize() {
      this.$eventBus.$emit("resize");
    },
  },
};
