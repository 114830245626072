import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

import { dashboard_router, dashboard_index_router } from "./dashboard_router";
import { user_router } from "./user_router";
import { shop_router } from "./shop_router";
import { order_router } from "./order_router";
import { finance_account_router } from "./finance_account_router";
import { finance_recharge_router } from "./finance_recharge_router";
import { finance_cash_router } from "./finance_cash_router";
import { settlement_router } from "./settlement_router";
import { transfer_router } from "./transfer_router";
import { notice_router } from "./notice_router";
import { refund_router } from "./refund_router";

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/components/layout"),
      children: [
        dashboard_router,
        dashboard_index_router,
        user_router,
        shop_router,
        order_router,
        finance_account_router,
        finance_recharge_router,
        finance_cash_router,
        settlement_router,
        transfer_router,
        notice_router,
        refund_router,
      ],
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/login/LogIn.vue"),
    },
  ],
});

// 路由拦截
router.beforeEach((to, from, next) => {
  if (to.meta.keepAlive) {
    store.commit("keepAlive/keepAlive", to.name);
  }
  next();
});

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

export default router;
