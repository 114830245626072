export const transfer_router = {
  path: "/transfer/index",
  name: "transfer",
  meta: {
    requiresAuth: true,
    breadcrumbName: "转账管理",
    menuPath: "/transfer/index",
  },
  menuName: "转账管理",
  icon: "el-icon-tickets",
  component: () => import("@/views/transfer/index.vue"),
};
