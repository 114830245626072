import dayjs from "dayjs";

const phoneReg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
const passwordReg = /^.{6,}$/;
const emailReg = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}$/;

export function checkPhone(num) {
  return phoneReg.test(num);
}

export function checkPassword(str) {
  return passwordReg.test(str);
}

export function checkEmail(str) {
  return emailReg.test(str);
}

export const timeFormat = (time, format = "YYYY-MM-DD") => {
  return dayjs(time).format(format);
};

export const diffInDays = (start, end) => {
  return Math.abs(dayjs(start).diff(end, "days"));
};

export default {
  checkPhone: checkPhone,
  checkPassword: checkPassword,
  checkEmail: checkEmail,
  timeFormat: timeFormat,
  diffInDays: diffInDays,
};
