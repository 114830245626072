export const finance_account_router = {
  path: "/finance/account",
  name: "account",
  meta: {
    requiresAuth: true,
    breadcrumbName: "账变记录",
    menuPath: "/finance/account",
  },
  menuName: "账变记录",
  icon: "el-icon-time",
  component: () => import("@/views/finance/account/index.vue"),
};
