import axios from "axios";
import store from "@/store";
import router from "@/router";

// 创建axios实例
const httpService = axios.create({
  baseURL: window.location.protocol + "//" + window.location.host,
  // 请求超时时间
  timeout: 0,
});

// request拦截器
httpService.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    if (store.getters.adminer) {
      // 让每个请求携带token
      config.headers["Authorization"] =
        store.getters.adminer.token_pre + " " + store.getters.adminer.token;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  }
);

// respone拦截器
httpService.interceptors.response.use(
  (response) => {
    // 统一处理状态
    const res = response.data;

    if ([901, 902, 903, 905].includes(res.code)) {
      // 跳转登陆页
      store.dispatch("adminlogout");
      router.push("/login");
      let error = new Error();

      error.message = res.message + ", 请重新登录！";
      return Promise.reject(error);
    } else if (res.code !== 200 && res.status !== 0) {
      // 返回异常
      return Promise.reject(res);
    } else {
      return res;
    }
  },
  // 处理处理
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = error.response?.data?.message ?? "错误请求";
          break;
        case 401:
          error.message = "未授权，请重新登录";
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = "请求错误,未找到该资源";
          break;
        case 405:
          error.message = "请求方法未允许";
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器端出错";
          break;
        case 501:
          error.message = "网络未实现";
          break;
        case 502:
          error.message = "网络错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网络超时";
          break;
        case 505:
          error.message = "http版本不支持该请求";
          break;
        case 11000:
          // error.message = 'http版本不支持该请求';
          break;
        default:
          error.message = `未知错误${error.response.status}`;
      }
    } else {
      error.message = "连接到服务器失败";
    }

    return Promise.reject(error);
  }
);

/* 网络请求部分 */

/*
 *  get请求
 *  url:请求地址
 *  params:参数
 * */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: "get",
      params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function post(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: "post",
      data: params,
      arrayFormat: "brackets",
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// 下载文件流
export function download(url, data, method = "post") {
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: method,
      data: data,
      responseType: "blob",
      headers: {
        Authorization:
          store.getters.adminer.token_pre + " " + store.getters.adminer.token,
        "Access-Control-Expose-Headers": "Content-disposition",
      },
    })
      .then((response) => {
        // 判断Bolb类型是否有错误信息
        if (
          response.data instanceof Blob &&
          response.data.type === "application/json"
        ) {
          // 如果错误信息是一个 JSON 格式的 Blob，那么读取 Blob 的内容并解析为 JSON 对象
          return response.data.text().then((text) => {
            const json = JSON.parse(text);
            // 返回一个包含错误信息的 Promise 对象
            return reject(json);
          });
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default {
  get,
  post,
  download,
};
